
import React, { useContext } from 'react'
import {motion} from 'framer-motion'
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { AccountContext } from '../../../auth/AuthProvider';
import NavItem from './NavItem';

const Topbar = ({sidebarVisible, setSidebarVisible, sidebarMobileVisible, setSidebarMobileVisible}) => {
    const { width, height } = useWindowDimensions();

    const {logout, login, userData} = useContext(AccountContext)

    return (
        <>
            <motion.div className="w-full h-[4.25rem] flex flex-row justify-between border-b border-soft-grey px-7 relative">
                <button className="h-6 aspect-square my-auto cursor-pointer" 
                onClick={() => {
                    width >= 1024 ?
                    (sidebarVisible ? setSidebarVisible(false) : setSidebarVisible(true)) :
                    (sidebarMobileVisible ? setSidebarMobileVisible(false) : setSidebarMobileVisible(true))
                }}
                >
                    <img src="/icons/hamburger.svg" alt="" />
                </button>
                <div className="flex flex-row gap-x-4 items-center group py-3.5">
                    <p>{userData && userData.name}</p>
                    <div className="h-full aspect-square rounded-full bg-primary" />
                    
                    <div className="absolute right-0 top-full w-[282px] h-fit pt-2 pr-2 hidden group-hover:block z-50">
                        <div className="bg-white border border-medium-grey pt-6">
                            <div className="py-3 flex flex-col items-center gap-y-4 pb-6 px-4">
                                <div 
                                    // src="http://via.placeholder.com/120"
                                    // alt="Profile Picture" 
                                    className="w-16 h-16 object-cover object-center rounded-full"
                                    // onError={(e) => {
                                    // e.target.src = '/global/no-profile.jpg';
                                    // }}
                                />
                                <div>
                                    <p className="text-sm leading-snug font-semibold text-center">{userData && userData.name || '-'}</p>
                                    <p className="text-2xs leading-[1.8] overflow-hidden text-center">{userData && userData.email || '-' }</p>
                                </div>
                            </div>
                            <div className="flex flex-col border-t border-soft-grey">
                                <NavItem link='/profile'>
                                    <div className="py-3 text-center">
                                        Profile
                                    </div>
                                </NavItem>
                                <NavItem link='/change-password'>
                                    <div className="py-3 text-center">
                                        Change Password
                                    </div>
                                </NavItem>
                                <button className="w-full font-semibold text-primary py-3 hover:bg-primary hover:text-white" onClick={logout}>
                                    LOGOUT
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

            </motion.div>
            
        </>
    )
}

export default Topbar